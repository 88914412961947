/*dashboard styles calendar starts here*/
.telekardia{
.width_330{
    min-width:330px;
    max-width:330px
}
.Home_left{
    width:1391px
}
.Home_right{
    width:320px
}
.dashboard_calendar .rbc-time-header {
    display: none;
}
.dashboard_calendar .rbc-day-slot .rbc-event{
    min-height: 60px;
}
.dashboard_calendar .rbc-timeslot-group{
    min-height: 240px;
    background: #F0F8F5;
}
.dashboard_calendar .rbc-day-slot .rbc-event {
    flex-direction: column-reverse !important;
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    justify-content: center;
    -webkit-justify-content:center;
}
.dashboard_calendar .rbc-calendar {
    /*background: #f0f8f5;*/
    height:calc(100vh - 175px) !important;
    min-height: 100% !important;
    border-right: 1px solid #cccccc59;
    width: calc(100% + -20px);
}
/*.dashboard_calendar .rbc-time-content .rbc-time-gutter.rbc-time-column, .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {*/
/*    background: #f0f8f5;*/
/*}*/
.dashboard_calendar .rbc-timeslot-group .rbc-time-slot {
    display: flex;
    display: -ms-flexbox;
    display: -moz-flex;
    /*display: -webkit-box;*/
    display: -moz-box;
    align-items: center;
    justify-content: flex-end;
    justify-items: center;
    -webkit-justify-content:flex-end;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
}
.dashboard_calendar .rbc-time-slot .rbc-label {
    position: relative;
    top:0
}
.dashboard_calendar .rbc-time-view, .dashboard_calendar .rbc-time-content{
    border:0px
}
.dashboard_calendar .rbc-event{
    background: #A582BB !important;
    border:0px !important;
}

.dashboard_calendar .rbc-event-label, .dashboard_calendar .rbc-day-slot .rbc-event-content {
    font-size: 100% !important;
}
.dashboard_calendar .rbc-day-slot .rbc-event-content {
    height:auto;
    flex: none;
}
.dashboard_calendar .rbc-event{
    padding: 0px 0px;
}
.dashboard_calendar .rbc-event.rbc-selected {
    background-color:#885FA5 !important;
    width: 100%!important;
    overflow: visible;
    left: 0% !important;
}
.dashboard_calendar .rbc-event{
    width: 100% !important;
    left: 0% !important;
}
.dashboard_calendar .rbc-event.rbc-selected:after{
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 50%;
    border: 10px solid transparent;
    border-left: 10px solid #885FA5;
    margin-top: -10px;
}
.dashboard_calendar .rbc-events-container .rbc-event-label {
    font-weight: 600;
    font-size: 16px;
}

.dashboard_calendar .patient_name .badge-info{
    color: #142A39;
    background-color: #f8f9fa;
    text-align: center;
    letter-spacing: 1.1px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    border-radius: 15px;
    padding: 0 5px;
    min-width: 100px;
}
.dashboard_calendar .rbc-time-content{
    overflow:visible !important;
}
.dashboard_calendar {
    overflow-y:auto !important;
    overflow-x: visible;
}
.dashboard_calendar .rbc-events-container .rbc-event-label,.dashboard_calendar .rbc-day-slot .rbc-event-content {
    padding-left: 10px;
}
/*.dashboard_calendar .rbc-day-slot .rbc-event{*/
/*  min-height: 36px;*/
/*}*/
/*dashboard styles Ends here*/
.dashboard_calendar .rbc-time-content .rbc-time-gutter.rbc-time-column, .dashboard_calendar .rbc-day-slot.rbc-time-column.rbc-now.rbc-today{
    background: #f0f8f5;
}
.dashboard_calendar .rbc-time-content .rbc-day-slot.rbc-time-column  {
    /*min-height: 100vh;*/
    /*min-height: 100%;*/
    min-height: 100%;
    height:100vh;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;

}
.dashboard_calendar .rbc-day-slot .rbc-events-container{
    min-height: 100vh
}
}